import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import("@/views/home/index.vue"),
  },
  {
    path: "/lawyer",
    name: "lawyer",
    component: () =>
      import("@/views/lawyer/index.vue"),
  },
  {
    path: "/caseDetails",
    name: "caseDetails",
    component: () =>
      import("@/views/lawyer/caseDetails.vue"),
  },
  {
    path: "/lawyerDetails",
    name: "lawyerDetails",
    component: () =>
      import("@/views/lawyer/details.vue"),
  },
  {
    path: "/lawOffice",
    name: "lawOffice",
    component: () =>
      import("@/views/lawOffice/index.vue"),
  },
  {
    path: "/lawDetails",
    name: "lawDetails",
    component: () =>
      import("@/views/lawOffice/details.vue"),
  },
  {
    path: "/user",
    name: "user",
    component: () =>
      import("@/views/user/index.vue"),
  },
  {
    path: "/ask",
    name: "ask",
    component: () =>
      import("@/views/ask/index.vue"),
  },
  {
    path: '/myAsk',
    name: 'myAsk',
    component: () => import('@/views/user/myAsk'),
  },
  {
    path: '/mySetting',
    name: 'mySetting',
    component: () => import('@/views/user/mySetting'),
  },
  {
    path: '/lawinfo',
    name: 'lawinfo',
    component: () => import('@/views/join/lawinfo'),
  },
  {
    path: '/lawyerinfo',
    name: 'lawyerinfo',
    component: () => import('@/views/join/lawyerinfo'),
  },
  {
    path: "/publicity",
    name: "publicity",
    component: () =>
      import("@/views/publicity/index.vue"),
  },
  {
    path: "/lawyerEnter",
    name: "lawyerEnter",
    component: () =>
      import("@/views/join/lawyerEnter.vue"),
  },
  {
    path: "/lawEnter",
    name: "lawEnter",
    component: () =>
      import("@/views/join/lawEnter.vue"),
  },
  {
    path: "/payOver",
    name: "payOver",
    component: () =>
      import("@/views/join/payOver.vue"),
  },
  {
    path: "/pay",
    name: "pay",
    component: () =>
      import("@/views/pay/index.vue"),
  },
  {
    path: "/WxXinAuth/ReceiveWxInfo",
    name: "ReceiveWxInfo",
    component: () =>
      import("@/views/wxauth/index.vue"),
  },
  {
    path: "/articleDetail",
    name: "articleDetail",
    component: () =>
      import("@/views/article/detail.vue"),
  },
  {
    path: "/signUp",
    name: "signUp",
    meta: {
      title: '创新申报',
    },
    component: () =>
      import("@/views/signUp/index.vue"),
  }

  
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;