import Vue from 'vue'
import storage from 'store'
import { getInfo,logout} from '@/api/login'
import {  ACCESS_TOKEN,WXQR_CODE_GUID } from '@/store/mutation-types'
export default  {
    state: {
      token: '',
      info: {},
      wxqr_code_guid:'',
      roles: [],
      shareUserId:'',
      localCity:{},
    },
    mutations: {
        SET_TOKEN: (state, token) => {
          state.token = token
          storage.set(ACCESS_TOKEN, token)
        },
        SET_INFO: (state, info) => {
            state.info = info
        },
        SET_SHARE_USER_ID: (state, shareUserId) => {
          state.shareUserId = shareUserId
      },
        SET_ROLES: (state, roles) => {
          state.roles = roles
        },
        WXQR_CODE_GUID: (state, guid) => {
            state.wxqr_code_guid = guid
        },
        LOCAL_CITY: (state, localCity) => {
          state.localCity = localCity
      }
    },
    actions: {
        // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          const result = response.data
          if (result) {
            commit('SET_ROLES', [])
            commit('SET_INFO', result)
          } else {
            reject(new Error('getInfo: roles must be a non-null array !'))
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
     // 登出
     Logout ({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_INFO', '')
          commit('SET_ROLES', [])
          commit('SET_SHARE_USER_ID', '')
          storage.remove(ACCESS_TOKEN)
          localStorage.clear()
          resolve()
        }).catch(() => {
          resolve()
        }).finally(() => {
        })
      })
    },
        setWXQRCodeGuid({ commit }, guid){
            return new Promise((resolve, reject) => {
              commit('WXQR_CODE_GUID', guid)
              resolve()
          })
        },
        setShareUserId({ commit }, userId){
          return new Promise((resolve, reject) => {
            commit('SET_SHARE_USER_ID', userId)
            resolve()
        })
      },
      setLocaCity({ commit }, cityObj){
        return new Promise((resolve, reject) => {
          commit('LOCAL_CITY', cityObj)
          resolve()
      })
    },
        setToken({ commit,dispatch }, token){
            return new Promise((resolve, reject) => {
              commit('SET_TOKEN', token)
              dispatch('GetInfo', commit).then(res => {
                resolve()
              })
          })
        }
    }
}