import request from '@/utils/request'
const userApi = {
  getInfo: '/login/getInfo',
  Logout: '/login/logout',
  // get my info
  wxMpQrCode: '/login/wxMpQrCode',
  wxQCodeLogin: '/login/wxQCodeLogin',
  lawyerUserAuth: '/login/lawyerUserAuth',
  teamUserAuth: '/login/teamUserAuth',
  areaList:'/area/list',
  getLawyerAuthInfo:'/login/getLawyerAuthInfo',
  getTeamAuthInfo:'/login/getTeamAuthInfo',
  getCardCount: '/orderCard/getCount',
  getMoniLogo: '/login/getMoniLogo',
  getAuthPrice:'/login/getAuthPrice'
}
/**
 * login func
 * @param parameter
 * @returns {*}
 */
//获取用户信息
export function getInfo() {
  return request({
    url: userApi.getInfo,
    method: 'get',
  })
}
/**
 * 根据省市获取认证价格
 * @param {*} parameter 
 * @returns 
 */
 export function getAuthPrice(parameter){
  return request({
    url: userApi.getAuthPrice,
    method: 'get',
    params: parameter
  })
}
export function getMoniLogo() {
  return request({
    url: userApi.getMoniLogo,
    method: 'get',
  })
}
//获取微信公众号授权二维码
export function wxMpQrCode(parameter) {
  return request({
    url: userApi.wxMpQrCode,
    method: 'get',
    params: parameter
  })
}
//微信扫前登录检测
export function wxQCodeLogin(parameter) {
  return request({
    url: userApi.wxQCodeLogin,
    method: 'get',
    params: parameter
  })
}

//律师认证下单
export function lawyerUserAuth(data) {
    return request({
      url: userApi.lawyerUserAuth,
      method: 'post',
      data: data,
      contentType: false, 
      processData: false, 
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }

//律所认证下单
export function teamUserAuth(data) {
    return request({
      url: userApi.teamUserAuth,
      method: 'post',
      data: data
    })
  }
export function logout() {
  return request({
    url: userApi.Logout,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function areaList() {
  return request({
    url: userApi.areaList,
    method: 'get',
  })
}
export function getLawyerAuthInfo(){
  return request({
    url: userApi.getLawyerAuthInfo,
    method: 'get',
  })
}
export function getTeamAuthInfo(){
  return request({
    url: userApi.getTeamAuthInfo,
    method: 'get',
  })
}

export function getCardCount(params){
  return request({
    url: userApi.getCardCount,
    method: 'get',
    params: params
  })
}
