import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {
    roles: state => state.user.roles,
    info:state => state.user.info,
    token:state => state.user.token,
    shareUserId:state => state.user.shareUserId,
    getWXWQCodeGuid :state =>state.user.wxqr_code_guid,
    localCity:state =>state.user.localCity
  },
  plugins: [  
    createPersistedState({ 
    storage: window.localStorage, 
  })],
  mutations: {},
  actions: {},
  modules: {
    user,
  },
});
