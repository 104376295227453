import moment from 'moment'
import notification from 'ant-design-vue/es/notification'
import request from '@/utils/request'
import message from 'ant-design-vue/es/message'
import store from '@/store'
import config from '@/config/defaultSettings'
export function matchFileType(fileName) {
  // 后缀获取
  let suffix = '';
  // 获取类型结果
  let result = '';
  if (!fileName) return false;
  try {
    // 截取文件后缀
    suffix = fileName.substr(fileName.lastIndexOf('.') + 1, fileName.length)
    // 文件后缀转小写，方便匹配
    suffix = suffix.toLowerCase()
  } catch (err) {
    suffix = '';
  }
  // fileName无后缀返回 false
  if (!suffix) {
    result = false;
    return result;
  }

  const fileTypeList = [ 
    // 图片类型
    {
      'typeName': 'image',
      'types': ['png', 'jpg', 'jpeg', 'bmp', 'gif']
    },
    {
      'typeName': 'pdf',
      'types': ['pdf']
    },
  ]
  for (let i = 0; i < fileTypeList.length; i++) {
    const fileTypeItem = fileTypeList[i]
    const typeName = fileTypeItem.typeName
    const types = fileTypeItem.types
    result = types.some(function (item) {
      return item === suffix;
    });
    if (result) {
      return typeName
    }
  }
  return 'other'
}

export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome() {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader(callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () {}
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}
// 通用下载方法
export function download(url, params, filename) {
  const notificationKey = 'download'
  notification.open({
    key: notificationKey,
    message: '正在下载数据，请稍候',
    duration: null,
    icon: h => {
      return h(
        'a-icon', {
          props: {
            type: 'loading'
          }
        }
      )
    }
  })
  return request.post(url, params, {
    transformRequest: [(params) => {
      return qs.stringify(params, {
        indices: false
      })
    }],
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    responseType: 'blob'
  }).then(async (data) => {
    const isLogin = await blobValidate(data)
    if (isLogin) {
      const blob = new Blob([data])
      saveAs(blob, filename)
      message.success('下载成功')
    } else {
      const resText = await data.text()
      const rspObj = JSON.parse(resText)
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
      message.error(errMsg)
    }
    notification.close(notificationKey)
  }).catch((r) => {
    message.error('下载文件出现错误，请联系管理员！')
    notification.close(notificationKey)
  })
}
export function isIE() {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}
export function scorePassword(pass) {
  let score = 0
  if (!pass) {
    return score
  }
  // award every unique letter until 5 repetitions
  const letters = {}
  for (let i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1
    score += 5.0 / letters[pass[i]]
  }

  // bonus points for mixing it up
  const variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass)
  }

  let variationCount = 0
  for (var check in variations) {
    variationCount += (variations[check] === true) ? 1 : 0
  }
  score += (variationCount - 1) * 10

  return parseInt(score)
}
var util = {
  /**
   * 验证字符串是否为空
   * @param {*} text 
   */
  isNotNull(text) {
    if (undefined === text) {
      return false
    }
    if (text.length === '' || text.length === 0) {
      return false
    }
    return true
  },
  /**
   * 错误提示框
   * @param {*} content 
   * @param {*} callback 
   */
  error(that, content, callback) {
    that.$notification['error']({
      message: '消息提醒',
      description: content,
      onClose() {
        if (callback !== undefined) {
          callback()
        }
      },
      duration: 2
    });
  },
  /**
   * 普通信息提示框
   * @param {*} content
   * @param {*} callback
   * @param {*} type  对应 success info warning error
   */
  msg(that, info, callback) {
    // if (!util.isNotNull(type)) {
    //   type = 'info'
    // }
    // alert(info)
    // if (callback) {
    //   setTimeout(() => {
    //     callback()
    //   }, 2000)
    // }
    that.$warning({
      title: '系统提示',
      content: info,
    })
    // that.$message.warning(info);
    if (callback) {
      setTimeout(() => {
        callback()
      }, 3000)
    }
  },
  /**
   * 确认弹出层
   * @param {*} title 
   * @param {*} content 
   * @param {*} callback 
   * @param {*} okText 
   */
  confrim(that, title, content, callback, okText, cancelText,cancel) {
    that.$confirm({
      title: title,
      content: content,
      centered: true,
      okText: okText !== undefined ? okText : '确认',
      cancelText: cancelText !== undefined ? cancelText : '取消',
      onOk() {
        if (callback !== undefined) {
          callback()
        }
      },
      onCancel() {
        if (cancel !== undefined) {
          cancel()
        }

      }
    })
  },
  /**
   * 格式化时间YYYY-MM-DD
   * @param {*} dataStr 
   */
  formatDate(dataStr) {
    if (dataStr === '') {
      return ''
    }
    return moment(dataStr).format('YYYY-MM-DD')
  },
  /**
   * 格式化时间YYYY-MM-DD HH:mm:ss
   * @param {*} dataStr 
   */
  formatDateTime(dataStr) {
    if (dataStr === '') {
      return ''
    }
    return moment(dataStr).format('YYYY-MM-DD HH:mm:ss')
  },

  windowOpen(url) {
    const aElement = document.createElement('a');
    aElement.setAttribute('href', url)
    aElement.setAttribute('target', '_blank')
    document.body.appendChild(aElement)
    aElement.click()
    document.body.removeChild(aElement)
  },
  isLogin(){
    if(store.getters.info.user){
      return true
    }
    return false
  },
  /**
   *获取id
   */
  guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0
      const v = c === 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    })
  },

  isWxWrok() {
    var ua = window.navigator.userAgent.toLowerCase();
    return /wxwork/i.test(ua);
  },

  // 分割日期
  extractDate(dateString) {
    const [year, month, day] = dateString.split('-');
    return {
      year: parseInt(year),
      month: parseInt(month),
      day: parseInt(day)
    };
  },
  /**
     * 分割手机号
     */
   formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) {
      return ''; // 可根据实际需求返回默认值或抛出异常
    }
    // 去除手机号中的空格和横线等特殊字符
    phoneNumber = phoneNumber.replace(/\s+/g, '').replace(/-/g, '');
    // 使用正则表达式匹配手机号的格式，并添加空格分隔符
    var formattedPhoneNumber = phoneNumber.replace(/^(\d{3})(\d{4})(\d{4})$/, '$1 $2 $3');
    return formattedPhoneNumber;
  },
/**
 * 计算年份
 */
  calculateYearsDiff(dateString) {
    if(!dateString){
      return ''
    }
    var date = new Date(dateString);
    var today = new Date();
    var yearsDiff = today.getFullYear() - date.getFullYear();
    // Check if the birthday has occurred this year
    if (today.getMonth() < date.getMonth() || (today.getMonth() === date.getMonth() && today.getDate() < date.getDate())) {
        yearsDiff--;
    }
    return yearsDiff;
  },

  /**
   * 根据律师执业证号获取律师年份
   * @param {*} number 
   * @returns 
   */
  getLawyerWorkAge(number) {
    if (number) {
        if (number.length < 17) {
            return 0;
        }
        let nowYear = new Date().getFullYear();
        let s = parseInt(number.substring(5, 9));
        let year = nowYear - s;
        if (year >= 1 && year < 50) {
            return year;
        } else if (year < 1) {
            return 1;
        } else if (year > 50) {
            return 1;
        }
    }
    return 0;
  },
    wxmpAuth(){
      let pages = window.location.href //获取加载的页面
      let authurl = config.wxAuthURL+pages
      location = authurl	 
  },
  //判断是否是微信浏览器的函数
  isWeiXin(){
    var ua = window.navigator.userAgent.toLowerCase()
    if (ua.indexOf('micromessenger') > -1) {
      return true
    } else {
      return false
    }
  }
}

 

export default util